import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { AuthService } from "./auth.service";
import { filter } from "rxjs/operators";
import { BehaviorSubject } from "rxjs";
import { WebDesigner } from "modeler/webdesigner";
import { LightType } from "modeler/render/render-scene";

export enum RenderPreset {
  Low = "Low",
  Medium = "Medium",
  High = "High",
  Ultra = "Ultra",
}

export enum TaskStatus {
  New,
  Performed,
  Suspended,
  Completed,
  Canceled,
  Error,
  Completion,
}

export interface TaskInfo {
  id: number;
  guid: string;
  fileName: string;
  message: string;
  dateCreate: string;
  resultStatus: TaskStatus;
  renderTime: string;
  preview: string;
}

interface CameraInfo {
  resX: number;
  resY: number;
  itemType: string;
  angleX: number;
  angleY: number;
  perspScale: number;
  location: string;
}

interface LightInfo {
  location: string;
  direction: string;
  itemType: string;
  power: number;
  shadowEnabled: boolean;
}

@Injectable({
  providedIn: "root",
})
export class RenderService {
  isOnlineAuth = new BehaviorSubject<boolean | undefined>(undefined);
  token: string = null;

  constructor(private http: HttpClient, private auth: AuthService) {
    this.isOnlineAuth.next(false);
    this.auth.isAuthenticated.pipe(filter((v) => !!v)).subscribe(async (_) => {
      await this.loginToOnLine();
    });
  }

  private async loginToOnLine() {
    let response = await this.http
      .get<any>(`/api/render/login/${this.auth.userId}`)
      .toPromise();

    this.token = response.token;
    this.isOnlineAuth.next(true);
  }

  getUserTasks(pageNumber: number) {
    return this.http
      .post<any>(`/api/render/gettasks/${pageNumber}`, {
        token: this.token,
      })
      .toPromise();
  }

  downloadResult(taskId: number) {
    return this.http
      .post(
        `/api/render/${taskId}/downloadresult`,
        {
          token: this.token,
        },
        { responseType: "blob" }
      )
      .toPromise();
  }

  downloadSharingResult(guid: string) {
    return this.http
      .post(
        `/api/render/${guid}/downloadresult`,
        {
          asUrl: true,
        },
        { responseType: "text" }
      )
      .toPromise();
  }

  createShareLink(guid: string) {
    return window.location.origin + `/render/view360/${guid}`;
  }

  createTask(fileId: string, ds: WebDesigner, task360: boolean = false) {
    const getRotationAngle = (cosTheta: number, sinTheta: number) => {
      return (Math.atan2(sinTheta, cosTheta) * 180) / Math.PI;
    };

    const vectorToStr = (vector: Float64Array | Float32Array) => {
      return `${vector[0]}, ${-vector[2]}, ${vector[1]}`;
    };

    const convertLightType = (type: LightType) => {
      switch (type) {
        case LightType.Point:
          return "POINT";
        case LightType.Spot:
          return "SPOT";
        case LightType.Sun:
          return "SUN";
        default:
          return "POINT";
      }
    };

    const angleX =
      90 - getRotationAngle(ds.camera.matrix[5], ds.camera.matrix[9]);
    const angleY = getRotationAngle(ds.camera.matrix[0], -ds.camera.matrix[8]);
    const perspScale = 1 / ds.camera.projectionMatrix()[5];

    const renderSettings = {
      render360: task360,
    };

    const camera: CameraInfo = {
      itemType: ds.camera.perspective ? "PERSP" : "ORTHO",
      resX: ds.canvas.width,
      resY: ds.canvas.height,
      location: vectorToStr(ds.camera.viewPos),
      perspScale,
      angleX,
      angleY,
    };

    const lights: LightInfo[] = [];
    for (let index = 0; index < ds.render.scene.lights.length; index++) {
      const item = ds.render.scene.lights[index];

      if (!item.enabled) continue;

      lights.push({
        direction: vectorToStr(item.direction),
        location: vectorToStr(item.position),
        power: item.power,
        itemType: convertLightType(item.type),
        shadowEnabled: item.shadows,
      });
    }

    const hidenObjects = [];
    if (!task360) {
      for (let key of Object.keys(ds.entityMap)) {
        const entity = ds.entityMap[key];

        if (
          (entity.data.wall || entity.data.ceiling) &&
          entity.renderLink.hidden
        ) {
          hidenObjects.push(entity.uidStr);
        }
      }
    }

    return this.http
      .post<number>(`/api/render/${fileId}/createtask`, {
        token: this.token,
        renderSettings,
        camera,
        lights,
        hidenObjects,
      })
      .toPromise();
  }
}
